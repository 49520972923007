import { TextField } from '@mui/material';
import React, { ComponentProps, FC, forwardRef, memo, useMemo } from 'react';

export interface I_Input_text extends ComponentProps<typeof TextField> {
  testid?: string;
}

const Input_text: FC<I_Input_text> = memo<I_Input_text>(
  forwardRef(({ testid, inputProps, ...rest }: I_Input_text, ref: any) => {
    const ip = useMemo(() => ({ ...inputProps, 'data-testid': testid }), [inputProps, testid]);

    return <TextField {...rest} inputProps={ip} ref={ref} data-testid="Input_text" />;
  }) as any,
);

export default Input_text;
