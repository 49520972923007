import { I_base_read } from '@/declaration/api/type/i_base_read';
import { Base } from '@/declaration/rds/model';
import { loading } from '@/utility/dynamic/loading';
import { uniqBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import dynamic from 'next/dynamic';
import React, { CSSProperties, memo, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { I_Lister, T_args, T_lister_context } from '../lister/lister';
import { I_Tree } from '../tree/tree';

const Lister = dynamic(() => import('../lister/lister'), { loading });
const Tree = dynamic(() => import('../tree/tree'), { loading });

export enum N_selector_as {
  list = 'list',
  tree = 'tree',
}

// @ts-ignore
export interface I_Selector<Row extends Base = any, Args extends T_args = I_base_read<Row>>
  extends I_Lister<Row, Args>,
    I_Tree<Row, Args> {
  as?: N_selector_as;
  label_prop_item?: keyof Row;
  key_prop?: keyof Row;
  multiple?: boolean;
  on_change?: (value: Row[]) => void;
  selection_reset?: number;
  selection?: Row[];
  class_list?: string;
  class_option?: string | ((it: Row, i: number) => string);
  style_item?: CSSProperties | ((it: Row, i: number) => CSSProperties);
  selectable?: ((row: Row) => boolean) | boolean;
  required?: boolean;
  render_row_inner?: (item: Row, index: number, context: T_lister_context) => ReactNode;
  className?: string;
}

const Selector = <Row extends object = any, Args extends T_args = I_base_read>(
  props: I_Selector,
): ReactElement<I_Lister<Row, Args>> => {
  const {
    as = N_selector_as.list,
    args,
    render_row,
    render_row_inner,
    multiple = false,
    label_prop_item,
    on_change,
    key_prop = 'id',
    selection_reset = 0,
    selection,
    class_list = '',
    class_option = '',
    style_item,
    selectable = true,
    required,
    className,
  } = props;
  const selection_prev = usePrevious(selection);
  const [_args, set__args] = useState<any>(args || {});
  const value_def = useMemo(() => (selection ? selection : []), [selection]);
  const [value, set_value] = useState<any>(value_def);
  const value_prev = usePrevious(value);
  const selection_reset_prev = usePrevious(selection_reset);
  const multiple_prev = usePrevious(multiple);
  const _class_option = useCallback(
    (it: Row, i: number) =>
      class_option ? (typeof class_option === 'string' ? class_option : class_option(it, i)) : '',
    [class_option],
  );
  const _style_item = useCallback(
    (it: Row, i: number) => style_item && (typeof style_item === 'function' ? style_item(it, i) : style_item),
    [style_item],
  );
  const [list, set_list] = useState<Row[]>([]);

  const _selectable = useCallback(
    (it: Row) => {
      if (typeof selectable === 'boolean') {
        return selectable;
      }
      return selectable && selectable(it);
    },
    [selectable],
  );

  /**
   * Find index of start selection
   */
  const find_i_from = useCallback(
    (to: number): number => {
      if (!selection?.length) {
        return 0;
      }
      const min = list.findIndex((it: any) => it[key_prop] === selection[0][key_prop]);
      const max = list.findIndex((it: any) => it[key_prop] === selection[selection.length - 1][key_prop]);
      if (to <= min) {
        return min;
      } else {
        return max;
      }
    },
    [key_prop, list, selection],
  );

  const handle_select = useCallback(
    (row: Row, i: number, e?: any) => {
      if (!_selectable(row)) {
        return;
      }
      const p = key_prop as keyof Row;
      if (multiple) {
        if (value.find((it: any) => it[p] === row[p])) {
          if (required && value.length === 1) {
            return;
          }
          set_value(value.filter((it: Row) => it[p] !== row[p]));
        } else {
          if (e?.shiftKey) {
            const from = find_i_from(i);
            const start = Math.min(i, from);
            const end = Math.max(i, from);
            const new_value = list.filter((it: Row, i: number) => i >= start && i <= end);
            set_value(uniqBy([...value, ...new_value], p));
          } else {
            set_value([...value, row]);
          }
        }
      } else {
        if (value[0]?.[p] === row?.[p]) {
          if (required) {
            return;
          }
          set_value([]);
        } else {
          set_value([row]);
        }
      }
    },
    [_selectable, key_prop, multiple, value, required, find_i_from, list],
  );

  const in_selection = useCallback(
    (id: any) => {
      return value?.find((it: Row) => it[key_prop as keyof Row] === id);
      // return multiple ? value?.find((it: Row) => it[key_prop as keyof Row] === id) : value[key_prop] === id;
    },
    [key_prop, value],
  );

  const _render_row = useCallback(
    (it: any, i: number, ctx: any) => {
      return (
        <div className="cursor-default select-none" onClick={(e: any) => handle_select(it, i, e)}>
          {render_row ? (
            render_row(it, i, ctx)
          ) : (
            <div
              style={_style_item(it, i)}
              className={`my-0.5 flex gap-2 rounded px-2 py-1 ${_class_option(it, i)} ${
                in_selection(it[key_prop])
                  ? 'bg-primary/50'
                  : _selectable(it)
                    ? 'hover:bg-foreground/5'
                    : 'cursor-not-allowed opacity-70'
              }`}
            >
              {render_row_inner
                ? render_row_inner(it, i, ctx)
                : label_prop_item
                  ? it[label_prop_item] ?? `key:${it[key_prop] ?? '-'}`
                  : JSON.stringify(it)}
            </div>
          )}
        </div>
      );
    },
    [
      _class_option,
      _selectable,
      _style_item,
      handle_select,
      in_selection,
      key_prop,
      label_prop_item,
      render_row,
      render_row_inner,
    ],
  );

  const reset_value = useCallback(() => {
    set_value([]);
  }, []);

  useEffect(() => {
    if (!isEqual(value_prev, value)) {
      on_change && on_change(value);
    }
  }, [on_change, value, value_prev]);

  useEffect(() => {
    if (multiple !== multiple_prev) {
      reset_value();
    }
  }, [multiple, multiple_prev, reset_value]);

  useEffect(() => {
    set__args(args);
  }, [args]);

  useEffect(() => {
    if (selection_reset !== selection_reset_prev) {
      reset_value();
    }
  }, [reset_value, selection_reset, selection_reset_prev]);

  useEffect(() => {
    if (!isEqual(selection, selection_prev)) {
      set_value(value_def);
    }
  }, [selection, selection_prev, value_def]);

  return (
    <div className={twMerge('flex flex-col rounded', className)}>
      <div className={`rounded ${class_list}`}>
        {as === N_selector_as.list ? (
          <Lister
            {...(props as any)}
            args={_args}
            render_row={_render_row}
            on_list_change={set_list}
            search_auto_focus
          />
        ) : (
          <Tree {...(props as any)} args={_args} render_row={_render_row} on_list_change={set_list} search_auto_focus />
        )}
      </div>
    </div>
  );
};

// const Lister = <Row extends Base_pk = any, Args extends T_args = I_base_read>({
const Selector_memo = memo<I_Selector>(Selector) as typeof Selector;

export default Selector_memo;
