import { Context_auth } from '@/context/context_auth';
import { Geo_node } from '@/declaration/rds/model';
import { N_lkey } from '@/declaration/type/n_lkey';
import lget from '@/utility/storage/lget';
import lset from '@/utility/storage/lset';
import last from 'lodash/last';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface O_use_user_position {
  nodes: Geo_node[] | null;
  /**
   * Last available node
   */
  last: Geo_node | null;
  setting_set_nodes: (v: Geo_node[] | null) => Promise<void>;
}

export function useUserPosition(): O_use_user_position {
  const { signer, setting_set } = useContext(Context_auth);
  const [nodes_ls, set_nodes_ls] = useState<Geo_node[] | null>();
  const nodes = useMemo<null | Geo_node[]>(
    () => signer?.r_user?.setting?.position?.nodes || nodes_ls || null,
    [nodes_ls, signer?.r_user?.setting?.position?.nodes],
  );
  const _last = useMemo(() => last(nodes?.filter((it) => !!it)) as Geo_node, [nodes]);

  const setting_set_nodes = useCallback(
    async (v: Geo_node[] | null) => {
      if (signer) {
        await setting_set('position.nodes', v);
        lset([N_lkey.setting, 'position'], null);
      } else {
        lset([N_lkey.setting, 'position', 'nodes'], v);
        set_nodes_ls(v);
      }
    },
    [setting_set, signer],
  );

  useEffect(() => {
    set_nodes_ls(lget([N_lkey.setting, 'position', 'nodes']));
  }, []);

  return {
    nodes,
    setting_set_nodes,
    last: _last,
  };
}
