import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Lister_down, { I_Lister_down } from '@/component/lister/helper/lister_down/lister_down';
import { I_Lister, N_lister } from '@/component/lister/lister';
import Re_card from '@/component/re/re_lister/re_card/re_card';
import Re_lister_filter from '@/component/re/re_lister/re_lister_filter/re_lister_filter';
import { I_re_public_read } from '@/declaration/api/type/i_re_public_read';
import { Re } from '@/declaration/rds/model';
import { useUserPosition } from '@/hook/geo/useUserPosition';
import { url_re } from '@/utility/url/url_re';
import merge from 'lodash/merge';
import Link from 'next/link';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { usePrevious, useToggle } from 'react-use';
import { useImmer } from 'use-immer';
import Re_card_text from './re_card/re_card_text/re_card_text';

export interface I_Re_lister extends Partial<I_Lister_down> {
  no_filter?: boolean;
  no_button_save?: boolean;
}

function Re_lister({ no_filter, args, no_button_save, ...rest }: I_Re_lister) {
  const args_prev = usePrevious(args);
  const { last } = useUserPosition();
  const [a, set_a] = useImmer<I_re_public_read>({
    geo_node_id: last?.id,
  });
  const [pending, pending_toggle] = useToggle(false);
  // todo: bug: hack: wait last node loaded
  const [open, open_toggle] = useToggle(false);
  const request = a.where?.request;

  const i_table = useMemo<I_Lister['i_table']>(
    () => ({
      columns: [
        {
          header: '标题',
          cell: ({ row: { original } }) => {
            return (
              <Link className="max-w-full text_cut" href={url_re(original.unique)} target="_blank">
                {original.title}
              </Link>
            );
          },
        },
        {
          header: '租金',
          cell: ({ row: { original } }) => {
            return (
              <div>
                <samp>{original.price_rent?.month}</samp>/ 月
              </div>
            );
          },
        },
        {
          header: '入住时间',
          cell: ({ row: { original } }) => {
            return <div>{original.time_move_out}</div>;
          },
        },
        {
          header: '操作',
          cell: ({ row: { original } }) => {
            return (
              <div>
                <Link href={url_re(original.unique)} target="_blank">
                  详情
                </Link>
              </div>
            );
          },
        },
      ],
    }),
    [],
  );

  const render_item = useCallback(
    (it: Re) => {
      return request ? <Re_card_text row={it} /> : <Re_card row={it} no_button_save={no_button_save} />;
    },
    [no_button_save, request],
  );

  const on_submit = useCallback(
    async (v: I_re_public_read) => {
      set_a(v);
    },
    [set_a],
  );

  useEffect(() => {
    set_a((s) => {
      s.geo_node_id = last?.id;
    });
  }, [last?.id, set_a]);

  useEffect(() => {
    const t = setTimeout(() => {
      open_toggle();
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [open_toggle]);

  useEffect(() => {
    if (!Object.is(args_prev, args)) {
      set_a(merge({}, a, args));
    }
  }, [a, args, args_prev, set_a]);

  return (
    <div className="c gap-3" data-testid="Re_lister">
      {!no_filter && <Re_lister_filter on_submit={on_submit} pending={pending} />}
      {open && (
        <div className="c gap-2">
          <Lister_down
            type={request ? N_lister.table : N_lister.list}
            i_table={i_table}
            on_pending_change={pending_toggle}
            class_wrapper={request ? '' : 'grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}
            api="re/public_read"
            args={a}
            render_row={render_item}
            {...rest}
          />
          <div className="c items-center">{pending && <Icon_loading />}</div>
        </div>
      )}
    </div>
  );
}

export default memo(Re_lister);
