import A_auth from '@/component/link/a_auth/a_auth';
import Re_card_text from '@/component/re/re_lister/re_card/re_card_text/re_card_text';
import Slider from '@/component/slider/slider';
import Tip_text from '@/component/tip/tip_text/tip_text';
import { Context_auth } from '@/context/context_auth';
import { Re } from '@/declaration/rds/model';
import { useApiSaveToggle } from '@/hook/re/useApiSaveToggle';
import { useQueryUrl } from '@/hook/router/useQueryUrl';
import { long_date } from '@/utility/date/long_date';
import { url_re } from '@/utility/url/url_re';
import Battery20Icon from '@mui/icons-material/Battery20';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Link from 'next/link';
import React, { HTMLProps, memo, useCallback, useContext, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useImmer } from 'use-immer';

export interface I_Re_card extends HTMLProps<HTMLDivElement> {
  row: Re;
  no_button_save?: boolean;
}

function Re_card({ className, row, no_button_save, ...rest }: I_Re_card) {
  const { signer } = useContext(Context_auth);
  const [v, set_v] = useImmer<Re>(row);
  const href = url_re(row.unique);
  const { toggle: save } = useApiSaveToggle(v.id, v.su_save);
  const q = useQueryUrl();
  // if row.time_renew is 3 days before

  const toggle_save = useCallback(async () => {
    const undo = v.su_save;
    await save({ id: v.id, undo });
    set_v((d) => {
      d.su_save = !undo;
    });
  }, [save, set_v, v.id, v.su_save]);

  useEffect(() => {
    set_v(row);
  }, [row, set_v]);

  return (
    <div
      {...rest}
      className={twMerge('c gap-2', row.fresh ? '' : 'grayscale-[70%] hover:grayscale-0', className)}
      data-testid="Re_card"
    >
      <div className="relative">
        <Link
          href={href}
          target="_blank"
          className="no_decorator aspect-[3/4] overflow-hidden rounded-lg r items-center bg-foreground/10"
        >
          {v.r_images && <Slider files={v.r_images} no_thumbs />}
        </Link>
        {!row.fresh && (
          <Tip_text title="发布者很久没有擦亮过了，信息可能已经失效">
            <div className="r items-center absolute top-1 left-1 z-10 drop-shadow-lg p-1">
              <Battery20Icon />{' '}
              {row.time_renew && <span className="help drop-shadow">更新于: {long_date(row.time_renew)}</span>}
            </div>
          </Tip_text>
        )}
        {!no_button_save && (
          <div className="absolute bottom-5 right-5 z-10 rounded drop-shadow-lg">
            {signer ? (
              <button onClick={toggle_save}>
                {v.su_save ? (
                  <FavoriteIcon fontSize="large" color="error" />
                ) : (
                  <FavoriteBorderIcon fontSize="large" color={'white' as any} />
                )}
              </button>
            ) : (
              <A_auth href={q({ x_login: '1' })}>
                <FavoriteBorderIcon fontSize="large" color={'white' as any} />
              </A_auth>
            )}
          </div>
        )}
      </div>
      <Re_card_text row={row} />
    </div>
  );
}

export default memo(Re_card);
