import { Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import React, { forwardRef, memo } from 'react';

export interface I_Chip_mini extends ChipProps {
}

const sx: ChipProps['sx'] = {
  height: 'auto',
  fontSize: '70%',
  padding: '2px 0',
  borderRadius: '18px',
  '& .MuiChip-icon': {
    fontSize: '17px', // Or adjust the font size here
  },
  '& .MuiChip-label': {
    padding: '0 7px',
  },
};

const Chip_mini = forwardRef(({ ...rest }: I_Chip_mini, ref) => (
  <Chip ref={ref as any} sx={sx} {...rest} data-testid="Chip_mini" />
));

export default memo(Chip_mini) as typeof Chip_mini;
