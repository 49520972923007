import Selector, { I_Selector } from '@/component/selector/selector';
import { I_base_read } from '@/declaration/api/type/i_base_read';
import { Geo_node } from '@/declaration/rds/model';
import { N_geo_node_level } from '@/declaration/rds/model/type/n_geo_node_level';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { useTranslation } from 'next-i18next';
import React, { memo, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';

export interface I_Geo_node_selector extends I_Selector<Geo_node> {
  className?: string;
  selection?: Geo_node[];
}

const base: I_base_read<Geo_node> = {
  select: ['id', 'pid', 'native_name', 'native_code', 'i'],
};
const def = (patch?: Partial<I_base_read<Geo_node>>): I_base_read<Geo_node> =>
  patch ? merge({}, patch, base) : { ...base };

function Geo_node_selector({ className, args, on_change, max_height, selection, ...rest }: I_Geo_node_selector) {
  max_height = max_height ?? 200;
  const { t } = useTranslation();
  const args_prev = usePrevious(args);
  const [args_province, set_args_province] = useState<I_base_read<Geo_node> | undefined>(
    merge({}, def({ where: { level: N_geo_node_level.province } }), args),
  );
  const [args_city, set_args_city] = useState<I_base_read<Geo_node> | undefined>(def());
  const [args_district, set_args_district] = useState<I_base_read<Geo_node> | undefined>(def());
  const [province, set_province] = useState<Geo_node>();
  const [city, set_city] = useState<Geo_node>();
  const [district, set_district] = useState<Geo_node>();
  const country = undefined; // todo: should support country in the future
  const prev_province = usePrevious(province);
  const prev_city = usePrevious(city);

  useEffect(() => {
    if (isEqual(args_prev, args)) {
      return;
    }
    set_args_province(merge({}, def(), args_province, args));
  }, [args_province, args, args_prev]);

  useEffect(() => {
    // country is undefined for now
    on_change?.([country as any, province as Geo_node, city as Geo_node, district as Geo_node]);
  }, [city, country, district, on_change, province]);

  useEffect(() => {
    if (province?.id === prev_province?.id) {
      return;
    }
    set_city(undefined);
    if (province?.id) {
      set_args_city(merge({}, def(), args, { where: { pid: province.id } }));
    } else {
      set_args_city({});
    }
  }, [args, prev_province?.id, province?.id]);

  useEffect(() => {
    if (city?.id === prev_city?.id) {
      return;
    }
    set_district(undefined);
    if (city?.id) {
      set_args_district(merge({}, def(), args, { where: { pid: city.id } }));
    } else {
      set_args_district({});
    }
  }, [args, city?.id, prev_city?.id]);

  return (
    <div {...rest} className={twMerge('r gap-1', className)} data-testid="Geo_node_selector">
      <div className="c gap-1 bg-foreground/5 rounded min-w-[70px]">
        <label className="help p-1">{t('geo:province')}</label>
        <Selector
          className="px-1"
          hide_indicator_end
          hide_indicator_start
          max_height={max_height}
          label_prop_item="native_name"
          api="geo_node/public_read"
          args={args_province}
          on_change={(node) => {
            set_province(node[0]);
            set_city(undefined);
            set_district(undefined);
          }}
          selection={selection?.[1] && [selection?.[1]]}
        />
      </div>

      {args_city?.where?.pid && (
        <>
          <div className="c gap-1 bg-foreground/5 rounded min-w-[70px]">
            <label className="help p-1">{t('geo:city')}</label>
            <Selector
              className="px-1"
              hide_indicator_end
              hide_indicator_start
              max_height={max_height}
              label_prop_item="native_name"
              api="geo_node/public_read"
              args={args_city as I_base_read<Geo_node>}
              on_change={(node) => {
                set_city(node[0]);
                set_district(undefined);
              }}
              selection={selection?.[2] && [selection?.[2]]}
            />
          </div>

          {args_district?.where?.pid && (
            <>
              <div className="c gap-1 bg-foreground/5 rounded min-w-[70px]">
                <label className="help p-1">{t('geo:district')}</label>
                <Selector
                  className="px-1"
                  hide_indicator_end
                  hide_indicator_start
                  max_height={max_height}
                  label_prop_item="native_name"
                  api="geo_node/public_read"
                  args={args_district as I_base_read<Geo_node>}
                  on_change={(node) => set_district(node[0])}
                  selection={selection?.[3] && [selection?.[3]]}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default memo(Geo_node_selector);
