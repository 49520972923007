export enum N_type_re {
  land = 'land', // 地块 - 未开发的土地，可能用于建设或农业
  house = 'house', // 房屋 - 独立的单家庭住宅，通常带有独立的院子
  parking = 'parking', // 停车位 - 专门用于停放车辆的空间
  warehouse = 'warehouse', // 仓库 - 用于存储货物的空间，通常是大型和开放式的
  complex = 'complex', // 综合体 - 包含多个建筑和相关设施的大型房地产项目
  building = 'building', // 建筑物 - 任何形式的建筑结构，可用作商业或住宅用途
  section = 'section', // 楼道单元 - 在同一建筑内，按功能或设计划分的区域
  floor = 'floor', // 楼层 - 建筑内部的水平层，通常包含多个房间或空间
  apartment = 'apartment', // 公寓 - 多户型建筑中的独立住宅单元
  room = 'room', // 房间 - 可能是宾馆的单个房间或合租住宅中的一个房间
}
