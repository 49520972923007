import Btn from '@/component/button/btn/btn';
import Geo_node_selector, { I_Geo_node_selector } from '@/component/geo/geo_node_selector/geo_node_selector';
import { Geo_node } from '@/declaration/rds/model';
import { useTranslation } from 'next-i18next';
import React, { HTMLProps, memo, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Geo_node_form extends HTMLProps<HTMLFormElement> {
  on_submit: (v: Geo_node[] | null) => void;
  selection?: I_Geo_node_selector['selection'];
  min_level?: number;
  nullable?: boolean;
}

function Geo_node_form({ className, on_submit, selection, min_level, nullable, ...rest }: I_Geo_node_form) {
  const { t } = useTranslation();
  const [v, set_v] = useState<Geo_node[] | null>(null);
  const disabled = min_level ? (v?.length ?? 0) < min_level : false;
  const is_empty = useMemo(() => !v || !v.filter((it) => !!it).length, [v]);

  const _submit = useCallback(
    (e: any) => {
      e.preventDefault();
      on_submit(v);
    },
    [on_submit, v],
  );

  const clear = useCallback(() => {
    set_v(null);
    on_submit(null);
  }, [on_submit]);

  return (
    <form {...rest} onSubmit={_submit} className={twMerge('c gap-3', className)} data-testid="Geo_node_form">
      <Geo_node_selector on_change={set_v} selection={selection} />
      <div className="r gap-1">
        <Btn className="flex-1" disabled={disabled} type="submit">
          {t('common:submit')}
        </Btn>
        {nullable && (
          <Btn disabled={disabled || is_empty} color="secondary" onClick={clear}>
            {t('common:clear')}
          </Btn>
        )}
      </div>
    </form>
  );
}

export default memo(Geo_node_form) as typeof Geo_node_form;
