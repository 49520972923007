import React, { memo, SVGAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Icon_magnifier extends SVGAttributes<any> {}

function Icon_magnifier({ className, ...rest }: I_Icon_magnifier) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={twMerge('size-[1em] inline-block', className)}
      data-testid="Icon_magnifier"
      {...rest}
    >
      <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clipRule="evenodd" />
    </svg>
  );
}

export default memo(Icon_magnifier) as typeof Icon_magnifier;
