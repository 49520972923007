import { I_re_save } from '@/declaration/api/type/i_re_save';
import { Re } from '@/declaration/rds/model';
import { useApi } from '@/hook/api/useApi';
import { useCallback } from 'react';

export function useApiSaveToggle(id: Re['id'], su_save: Re['su_save']): {
  toggle: (opt: I_re_save) => Promise<void>,
  pending: boolean
} {
  const { call: save, pending } = useApi('re/save');

  const toggle = useCallback(async () => {
    const undo = su_save;
    await save({ id: id, undo });
  }, [save, id, su_save]);

  return {
    toggle,
    pending,
  };
}
