import Pop from '@/component/popper/pop/pop';
import React, { HTMLProps, memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Quality_label extends HTMLProps<HTMLDivElement> {
  value?: number;
}

function Quality_label({ className, value, ...rest }: I_Quality_label) {
  const quality_bg = useMemo(() => {
    if (typeof value !== 'number') return 'bg-foreground/20';
    if (value < 0.5) return 'bg-red-500/70';
    if (value < 0.8) return 'bg-orange-500/80';
    return 'bg-green-500/70';
  }, [value]);

  return (
    <Pop content="信息质量评分，反映了信息填写的详细程度，满分为 100 分">
      <div
        {...rest}
        className={twMerge(`py-1 px-2 rounded text-sm ${quality_bg} r gap-[3px] items-center`, className)}
        data-testid="Quality_label"
      >
        <samp className="opacity-70">评分</samp>
        <samp className="font-bold">{value ? Math.round(value * 100) : '-'}</samp>
      </div>
    </Pop>
  );
}

export default memo(Quality_label) as typeof Quality_label;
