import startCase from 'lodash/startCase';
import { env } from '../../env/env';

export interface T_title_build {
  separator?: string;
  no_brand?: boolean;
  keep_case?: boolean;
}

export function title_build(segments: string | string[], opt?: T_title_build): string {
  const { separator, no_brand, keep_case } = opt ?? {};
  if (typeof segments === 'string') {
    segments = [segments];
  }

  segments = segments.map((it) => (it?.length ? (keep_case ? it : startCase(it)) : '-'));
  if (!no_brand) {
    segments.push(env.brand);
  }

  return segments.join(separator ?? ' • ');
}
