import Btn from '@/component/button/btn/btn';
import Geo_node_form from '@/component/geo/geo_node_form/geo_node_form';
import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Icon_magnifier from '@/component/icon/icon_magnifier/icon_magnifier';
import Input_text from '@/component/input/input_text/input_text';
import Modal_general from '@/component/modal/modal_general/modal_general';
import { I_base_read } from '@/declaration/api/type/i_base_read';
import { I_re_public_read } from '@/declaration/api/type/i_re_public_read';
import { Geo_node, Re } from '@/declaration/rds/model';
import { useUserPosition } from '@/hook/geo/useUserPosition';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FormControlLabel, Switch } from '@mui/material';
import last from 'lodash/last';
import set from 'lodash/set';
import React, { HTMLProps, memo, useCallback, useEffect, useMemo } from 'react';
import { usePrevious, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { useImmer } from 'use-immer';

export interface I_Re_lister_filter extends HTMLProps<HTMLFormElement> {
  on_change?: (v: I_base_read<Re>) => void;
  on_submit?: (v: I_base_read<Re>) => void;
  pending?: boolean;
}

function Re_lister_filter({ className, on_change, on_submit, pending, ...rest }: I_Re_lister_filter) {
  const { nodes, setting_set_nodes } = useUserPosition();
  const node = useMemo<Geo_node>(() => last(nodes?.filter((it: Geo_node) => !!it)) as Geo_node, [nodes]);
  const [form, set_form] = useImmer<I_re_public_read>({
    where: {
      request: false,
    },
  });
  const form_prev = usePrevious(form);
  const disabled = pending;
  const [open_location, open_location_toggle] = useToggle(false);
  const _on_submit = useCallback(
    (e: any) => {
      e.preventDefault();
      on_submit?.(form);
    },
    [form, on_submit],
  );

  useEffect(() => {
    on_change?.(form);
  }, [form, on_change]);

  useEffect(() => {
    if (form_prev?.where?.request !== form?.where?.request) {
      on_submit?.(form);
    }
  }, [form, form_prev?.where?.request, on_submit]);

  return (
    <>
      <form
        {...rest}
        className={twMerge('flex flex-col gap-2 md:flex-row', className)}
        data-testid="Re_lister_filter"
        onSubmit={_on_submit}
      >
        <div className="flex flex-row flex-wrap">
          <div>
            <Btn onClick={open_location_toggle}>
              <LocationOnIcon /> {node ? node.native_name : '位置'}
            </Btn>
          </div>
          <div className="r items-center">
            <FormControlLabel
              labelPlacement="top"
              control={
                <Switch
                  size="medium"
                  value={form.where?.request ?? false}
                  onChange={(e) => {
                    set_form((s) => {
                      set(s, ['where', 'request'], e.target.checked);
                    });
                  }}
                />
              }
              label="求租"
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-1">
          <Input_text
            fullWidth
            autoComplete="off"
            type="search"
            label="搜索标题，描述"
            value={form.keyword || ''}
            onChange={(v) => {
              set_form((d) => {
                d.keyword = v.target.value;
              });
            }}
          />
          <div>
            <Btn disabled={disabled} type="submit" className="text-[30px]">
              {pending ? <Icon_loading className="text-[30px]" /> : <Icon_magnifier className="text-[30px]" />}
            </Btn>
          </div>
        </div>
      </form>
      <Modal_general open={open_location} onClose={open_location_toggle} title="位置">
        <div className="c gap-4">
          <h2 className="title">选择城市</h2>
          <Geo_node_form
            nullable
            selection={nodes ?? undefined}
            on_submit={async (v) => {
              const list = v?.filter((it) => !!it);
              const node = last(list);
              const id = node?.id;
              set_form((s) => {
                s.geo_node_id = id;
              });
              on_submit?.({ ...form, geo_node_id: id } as I_re_public_read);
              open_location_toggle(false);
              await setting_set_nodes(v ?? null);
            }}
          />
        </div>
      </Modal_general>
    </>
  );
}

export default memo(Re_lister_filter) as typeof Re_lister_filter;
