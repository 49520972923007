import Img from '@/component/image/img';
import { File } from '@/declaration/rds/model';
import React, { ComponentProps, HTMLProps, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperSlideProps } from 'swiper/swiper-react';
import { twMerge } from 'tailwind-merge';

export interface I_Slider extends HTMLProps<HTMLDivElement> {
  files: File[];
  i_swiper?: ComponentProps<typeof Swiper>;
  i_thumbs?: ComponentProps<typeof Swiper>;
  i_item_swiper?: SwiperSlideProps;
  i_item_thumb?: SwiperSlideProps;
  gap?: number;
  on_click?: (it: File) => void;
  on_thumb_click?: (it: File) => void;
  hidden_single_thumb?: boolean;
  auto_height?: boolean;
  no_thumbs?: boolean;
}

const modules_main = [FreeMode, Navigation, Thumbs];
const modules_thumb = [FreeMode, Thumbs];

function Slider({
  className,
  files,
  gap = 2,
  on_click,
  on_thumb_click,
  hidden_single_thumb,
  auto_height,
  i_thumbs,
  i_swiper,
  i_item_thumb,
  i_item_swiper,
  no_thumbs,
  ...rest
}: I_Slider) {
  const [thumbs_swiper, set_thumbs_swiper] = useState<any>(null);
  const thumbs = useMemo(
    () => ({
      swiper: thumbs_swiper && !thumbs_swiper.destroyed ? thumbs_swiper : null,
    }),
    [thumbs_swiper],
  );
  const single = files.length === 1;
  const gap_style = useMemo(() => ({ height: gap }), [gap]);

  const mapper = useCallback(
    (thumb?: boolean) =>
      (it: File): ReactNode => (
        <SwiperSlide
          onClick={() => (thumb ? on_thumb_click?.(it) : on_click?.(it))}
          key={it.id}
          {...(thumb ? i_item_thumb : i_item_swiper)}
        >
          {it.mime?.startsWith('video/') ? (
            <video
              src={it.url?.medium ?? it.url?.large ?? it.url?.small ?? it.url?.source}
              controls={!thumb}
              preload={thumb ? 'metadata' : 'auto'}
            />
          ) : (
            <Img src={it.url?.medium ?? it.url?.large ?? it.url?.small ?? it.url?.thumbnail} />
          )}
        </SwiperSlide>
      ),
    [i_item_swiper, i_item_thumb, on_click, on_thumb_click],
  );

  if (!files.length) {
    return null;
  }

  return (
    <div {...rest} className={twMerge('max-w-full', className)} data-testid="Slider">
      <Swiper
        loop={!single}
        navigation={!isMobile && !single}
        thumbs={thumbs}
        modules={modules_main}
        {...i_swiper}
        autoHeight={auto_height}
        className={twMerge('max-h-[calc(100vh-100px)]', i_swiper?.className)}
      >
        {files.map(mapper(false))}
      </Swiper>
      {(!hidden_single_thumb || !single) && !no_thumbs && (
        <>
          <div style={gap_style} />
          <Swiper
            freeMode
            watchSlidesProgress
            height={50}
            loop={!single}
            onSwiper={set_thumbs_swiper as any}
            spaceBetween={gap}
            slidesPerView={5}
            modules={modules_thumb}
            {...i_thumbs}
            className={twMerge('swiper_thumb_list max-h-[200px]', i_thumbs?.className)}
          >
            {files.map(mapper(true))}
          </Swiper>
        </>
      )}
    </div>
  );
}

export default memo(Slider);
