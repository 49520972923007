import HelpIcon from '@mui/icons-material/Help';
import { Box, ClickAwayListener, Fade, Popper, PopperProps } from '@mui/material';
import { Options } from '@popperjs/core';
import React, { DOMAttributes, FC, memo, ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Pop extends DOMAttributes<HTMLButtonElement> {
  content: ReactNode;
  children?: ReactNode;
  i_popover?: Partial<PopperProps>;
  class_trigger?: string;
  class_content?: string;
  animation_duration?: number;
}

const modifiers: Options['modifiers'] = [
  // {
  //   name: 'offset',
  //   options: {
  //     offset: [0, 0],
  //   },
  // },
];

const sx = { fontSize: '100%' };
const Pop: FC<I_Pop> = memo<I_Pop>(
  ({ children, content, i_popover, class_content, class_trigger, animation_duration, ...rest }: I_Pop) => {
    const [anchor, set_anchor] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchor);
    const id = open ? 'pop' : undefined;

    const handle_click = useCallback(
      (e: any) => {
        set_anchor(anchor ? null : e.currentTarget);
      },
      [anchor],
    );

    const handle_close = useCallback(() => {
      set_anchor(null);
    }, []);

    return (
      <>
        <ClickAwayListener onClickAway={handle_close}>
          <div data-testid="Pop" className="inline-block">
            <div
              onClick={handle_click}
              className={twMerge(
                `inline-block cursor-context-menu underline decoration-dotted underline-offset-4 hover:no-underline`,
                class_trigger,
              )}
            >
              {children ? (
                children
              ) : (
                <button className="opacity-80 hover:opacity-100" type="button" {...rest}>
                  <HelpIcon sx={sx} />
                </button>
              )}
            </div>
            <Popper
              id={id}
              open={open}
              anchorEl={anchor}
              placement="top"
              modifiers={modifiers}
              transition
              {...i_popover}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={animation_duration ?? 200}>
                  <Box
                    className={twMerge(
                      `px-2 py-1 m-[10px] max-w-[330px] rounded-md bg-background_dull/90 drop-shadow text-sm`,
                      class_trigger,
                    )}
                  >
                    {typeof content === 'string' ? <div className="whitespace-pre-line">{content}</div> : content}
                  </Box>
                </Fade>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
      </>
    );
  },
);

export default Pop;
