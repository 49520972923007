import { I_base_read } from '@/declaration/api/type/i_base_read';
import { Base } from '@/declaration/rds/model';
import React, { HTMLAttributes, memo, ReactElement } from 'react';
import Lister, { I_Lister, T_args } from '../../lister';

// @ts-ignore
export interface I_Lister_down<Row extends Base = any, Args extends T_args = I_base_read<Row>>
  extends I_Lister<Row, Args> {
  i_el?: HTMLAttributes<HTMLDivElement>;
}

/**
 * Lister top-to-bottom
 */
const Lister_down = <Row extends object = any, Args extends T_args = I_base_read>(
  props: I_Lister_down<Row, Args>,
): ReactElement<I_Lister_down<Row, Args>> => {
  return <Lister {...props} hide_indicator_start />;
};

export default memo<I_Lister_down>(Lister_down) as typeof Lister_down;
