import Chip_mini from '@/component/chip/chip_mini/chip_mini';
import Pop from '@/component/popper/pop/pop';
import Quality_label from '@/component/re/quality_label/quality_label';
import { Re } from '@/declaration/rds/model';
import { N_status_legal_entity } from '@/declaration/rds/model/type/n_status_legal_entity';
import { N_type_re } from '@/declaration/rds/model/type/n_type_re';
import { long_date } from '@/utility/date/long_date';
import { url_re } from '@/utility/url/url_re';
import HelpIcon from '@mui/icons-material/Help';
import VerifiedIcon from '@mui/icons-material/Verified';
import Link from 'next/link';
import React, { HTMLProps, memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface I_Re_card_text extends HTMLProps<HTMLDivElement> {
  row: Re;
}

function Re_card_text({ className, row, ...rest }: I_Re_card_text) {
  const href = url_re(row.unique);
  const reviewer = row.r_lease_latest?.r_review_latest?.r_owner;
  const verified = row.r_lease_latest?.r_review_latest?.r_owner?.legal_entity_status === N_status_legal_entity.verified;
  const name = `${reviewer?.nickname ?? ''}@${reviewer?.unique ?? ''}`;
  const quality_bg = useMemo(() => {
    if (typeof row.quality !== 'number') return 'bg-foreground/20';
    if (row.quality < 0.5) return 'bg-red-500/70';
    if (row.quality < 0.8) return 'bg-orange-500/80';
    return 'bg-green-500/70';
  }, [row.quality]);

  return (
    <div {...rest} className={twMerge('c gap-2 px-2', className)} data-testid="Re_card_text">
      <div className="r gap-1 items-center ">
        <Quality_label value={row.quality} className="text-xs" />
        <div className="py-1 px-2 rounded bg-foreground/20 text-xs">
          {[N_type_re.room].includes(row.type) ? '合租' : '整租'}
        </div>
        <Link className="max-w-full flex-1 text_cut no-underline font-bold" href={href} target="_blank">
          {row.title ?? `#${row.unique}`}
        </Link>
      </div>
      <div className="r gap-1 items-center flex-wrap">
        <div className="flex-1 r items-center">
          <samp className="rounded bg-foreground/10 px-2">
            ¥ <samp className="font-bold text-xl">{row.r_lease_latest?.price_rent?.month}</samp>/月
          </samp>
        </div>
        <div className="r items-center">
          {row.time_move_out && <div className="help">{long_date(row.time_move_out)} 前搬出</div>}
        </div>
      </div>
      <div className="r gap-1 items-center">
        <div title={name} className="help max-w-[110px] text_cut">
          {name}
        </div>
        {verified ? (
          <Pop content="已实名认证">
            <Chip_mini label="已实名" color="success" icon={<VerifiedIcon />} />
          </Pop>
        ) : (
          <Pop content="未实名认证">
            <Chip_mini size="small" label="未实名" icon={<HelpIcon />} />
          </Pop>
        )}
      </div>
      <div className="c gap-1 flex-wrap">
        {row.r_f_travel?.subway && (
          <div className="text-sm r gap-1 help">
            <div className="px-1 rounded bg-foreground/10">{row.r_f_travel?.subway?.[0]?.distance}m</div>
            {row.r_f_travel?.subway?.[0]?.name}
          </div>
        )}
        {row.r_f_travel?.subway && (
          <div className="text-sm r gap-1 help">
            <div className="px-1 rounded bg-foreground/10">{row.r_f_travel?.bus?.[0]?.distance}m</div>
            {row.r_f_travel?.bus?.[0]?.name}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Re_card_text) as typeof Re_card_text;
